import getConfig from 'next/config';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

export const WEB_NANE = publicRuntimeConfig.WEB_NANE;
export const APP_ENV = publicRuntimeConfig.NEXT_PUBLIC_APP_ENV || 'production';
export const API_URI = process.env.API_URL || publicRuntimeConfig.NEXT_PUBLIC_API_URL;
export const APP_URL = publicRuntimeConfig.NEXT_PUBLIC_APP_URL;
export const APP_IMG = publicRuntimeConfig.NEXT_PUBLIC_IMG;
export const NEXT_DAILYMOTION_ID = publicRuntimeConfig.NEXT_DAILYMOTION_ID;

export const TRUE_HITS_URL = publicRuntimeConfig.NEXT_PUBLIC_TRUE_HITS_URL;

export const REDIS_URL = publicRuntimeConfig.NEXT_PUBLIC_REDIS_URL;
export const REDIS_PW = 'Nation#2021';
export const REDIS_PORT = 6379;

export const NEWSFEED_URL = process.env.NEXT_PUBLIC_NEWSFEED_URL || publicRuntimeConfig.NEXT_PUBLIC_NEWSFEED_URL;

export const SERVER_GSERVICE_URL = process.env.SERVER_GSERVICE_URL || publicRuntimeConfig.NEXT_PUBLIC_SERVER_GSERVICE_URL;
export const CLIENT_GSERVICE_URL = process.env.CLIENT_GSERVICE_URL || publicRuntimeConfig.NEXT_PUBLIC_CLIENT_GSERVICE_URL;

export const isProd = APP_ENV.toLowerCase() === 'production';
export const IS_NO_ROBOT_INDEX = !isProd;
export const IS_SHOW_COOKIE = publicRuntimeConfig.IS_SHOW_COOKIE || process.env.IS_SHOW_COOKIE;

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || publicRuntimeConfig.NEXT_PUBLIC_GTM_ID;

export const RECAPTCHA_SIT_KEY = '6LdCI24cAAAAAF4KSKMe3mkz8MfWNng43W-rnn3p';
export const RECAPTCHA_SECRET_KEY = '6LdCI24cAAAAALnOkQHgaIME5LV2Y0pQw9ceVNUx';

export const SHOW_COMMEMORATE = publicRuntimeConfig.SHOW_COMMEMORATE || process.env.SHOW_COMMEMORATE;
export const NEXT_PUBLIC_IS_COMMEMORATE = publicRuntimeConfig.NEXT_PUBLIC_IS_COMMEMORATE || process.env.NEXT_PUBLIC_IS_COMMEMORATE;

export const SHOW_RIBBON = publicRuntimeConfig.SHOW_RIBBON || process.env.SHOW_RIBBON;
export const FILE_CLOSE = process.env.FILE_CLOSE || publicRuntimeConfig.FILE_CLOSE;
export const TYPE_FILE_CLOSE = process.env.TYPE_FILE_CLOSE || publicRuntimeConfig.TYPE_FILE_CLOSE;

export const SHOW_IMAGE_DESC_ID = publicRuntimeConfig.SHOW_IMAGE_DESC_ID || process.env.SHOW_IMAGE_DESC_ID || 0;
export const EMBED_ELECTION = publicRuntimeConfig.EMBED_ELECTION || process.env.EMBED_ELECTION;
export const IFRAME_ELECTION = publicRuntimeConfig.IFRAME_ELECTION || process.env.IFRAME_ELECTION;

export const EMBED_ELECTION_ON_WEB = publicRuntimeConfig.EMBED_ELECTION_ON_WEB || process.env.EMBED_ELECTION_ON_WEB;
export const ELECTION_POLL_NEXT_PUBLIC_GTM_ID = publicRuntimeConfig.ELECTION_POLL_NEXT_PUBLIC_GTM_ID;

// speech
export const NEXT_PUBLIC_CLOSE_TRANSLATE = publicRuntimeConfig.NEXT_PUBLIC_CLOSE_TRANSLATE;
export const NEXT_PUBLIC_URL_SOUND = publicRuntimeConfig.NEXT_PUBLIC_URL_SOUND;
export const NEXT_PUBLIC_URL_PATH_SOUND = publicRuntimeConfig.NEXT_PUBLIC_URL_PATH_SOUND;
export const NEXT_PUBLIC_WEB_NAME_SOUND = publicRuntimeConfig.NEXT_PUBLIC_WEB_NAME_SOUND;
export const NEXT_PUBLIC_ID_MOCK_SOUND = publicRuntimeConfig.NEXT_PUBLIC_ID_MOCK_SOUND;
export const NEXT_PUBLIC_PLACEHOLDER_TITLE_SUOND = publicRuntimeConfig.NEXT_PUBLIC_PLACEHOLDER_TITLE_SUOND;
export const NEXT_PUBLIC_PLACEHOLDER_TIME_OUT = publicRuntimeConfig.NEXT_PUBLIC_PLACEHOLDER_TIME_OUT;
export const NEXT_PUBLIC_SPEED_KEYPOINT = publicRuntimeConfig.NEXT_PUBLIC_SPEED_KEYPOINT;

// AUTH0
export const AUTH0_BASE_URL = serverRuntimeConfig.AUTH0_BASE_URL;
export const AUTH0_ISSUER_BASE_URL = serverRuntimeConfig.AUTH0_ISSUER_BASE_URL;
export const AUTH0_CLIENT_ID = serverRuntimeConfig.AUTH0_CLIENT_ID;
export const AUTH0_CLIENT_SECRET = serverRuntimeConfig.AUTH0_CLIENT_SECRET;
export const REDIS_HOST = serverRuntimeConfig.REDIS_HOST;
export const REDIS_PASSWORD = serverRuntimeConfig.REDIS_PASSWORD;
export const REDIS_TTL = serverRuntimeConfig.REDIS_TTL;
export const NEXT_PUBLIC_DEFAULT_COUNT_VIEW = publicRuntimeConfig.NEXT_PUBLIC_DEFAULT_COUNT_VIEW;
export const NEXT_PUBLIC_TIMER_LANDING_PAGE = publicRuntimeConfig.NEXT_PUBLIC_TIMER_LANDING_PAGE;

export const NEXT_PUBLIC_LOGIN_COOKIE_DATE = publicRuntimeConfig.NEXT_PUBLIC_LOGIN_COOKIE_DATE || 1;
export const NEXT_PUBLIC_LOGIN_STATE_DATE = publicRuntimeConfig.NEXT_PUBLIC_LOGIN_STATE_DATE || 1;
// youtube
export const NEXT_PUBLIC_API_YOUTUBE = publicRuntimeConfig.NEXT_PUBLIC_API_YOUTUBE;
export const NEXT_PUBLIC_CHANEL_ID = publicRuntimeConfig.NEXT_PUBLIC_CHANEL_ID;
// special
export const NEXT_PUBLIC_SHOW_LINK_SPECIAL = publicRuntimeConfig.NEXT_PUBLIC_SHOW_LINK_SPECIAL;
